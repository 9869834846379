import {Injectable} from '@angular/core';
import {FxConfirmModalComponent} from './fx-confirm-modal.component';
import {DwMessageBaseService,DwMessageService, DwModalService} from 'ng-quicksilver';
import {FxBatchModalComponent} from './fx-batch-modal.component';
import {FxSearchModalComponent, FxSearchModalparam, FxSearchModelColumn, FxSearchModelConfig} from './fx-search-modal.component';
import {FxSearchBackendPaginationModalComponent} from './fx-search-backend-pagination-modal.component';
import {Observable} from 'rxjs';
import {FxIconPickerModalComponent} from './fx-icon-picker-modal/fx-icon-picker-modal.component';
// import {HttpClient} from '@angular/common/http';
import {FxCharacteristicModalComponent } from './fx-characteristic-modal/fx-characteristic-modal.component';
import {FxOptionsModalComponent } from './fx-options-modal/fx-options-modal.component';
import { DwHttpClient } from '../../../../../../projects/webdpt/framework/http';
import { FxDapHttpClient } from '../../fx-http-client';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { FxSearchSelfModalComponent } from './fx-search-self-modal.component';

@Injectable({
  providedIn: 'root',
})
export class FxModalService {

  constructor(public dwModalService: DwModalService,
              // public http: FxDapHttpClient,
              public http: DwHttpClient,
              public fxUserService: FxUserInfoService,
              public dwMessage: DwMessageService,
              ) {
  }

  public create = this.dwModalService.create;
  // public flag1 : boolean = true;
  

  public confirm(parameters: {
    title?: string,
    message: string,
    moreInfo?: string,
    programName?: string,
    programNo?: string,
    isRemind?: any,
    okFn?: any,
    cancelFn?: any,
    className?: any,
  }): any {
    const {title, message, moreInfo, programName, programNo, isRemind, okFn, cancelFn, className} = parameters;
    const modal = this.dwModalService.create({
      dwTitle: null,
      dwStyle: {top: '20px'},
      //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
      dwMaskClosable: false, // 點擊遮罩是否允許關閉
      dwContent: FxConfirmModalComponent,
      dwWrapClassName: 'fx_md_modal',
      dwClosable: false,
      dwClassName: className,
      dwFooter: [
        {
          label: '取消',
          shape: 'default',
          onClick: (data: any): any => {
            modal.destroy();
            if (typeof cancelFn === 'function') {
              setTimeout(cancelFn(data), 0);
            }
          }
        },
        {
          label: '确定',
          type: 'primary',
          onClick: (data: any): any => {
            modal.close({});
            if (typeof okFn === 'function') {
              setTimeout(okFn(data), 0);
            }
          }
        }
      ],
      // dwGetContainer: () => {
      //   let a = document.createElement('div');
      //   a.className = 'aaa';
      //   return a;
      // },
      dwComponentParams: {
        title: title,
        message: message,
        moreInfo: moreInfo,
        program_name: programName,
        program_no: programNo,
        isRemind: isRemind
      }
    });
  }

  public confirm$(parameters: {
    title?: string,
    message: string,
    moreInfo?: string,
    programName?: string,
    programNo?: string,
    isRemind?: any
  }): Observable<any> {
    // const {title, message, moreInfo, programName, programNo, isRemind} = parameters;
    return Observable.create((observer) => {
      try {
        const confirmParameter = Object.assign(parameters, {
          okFn: (data): any => {
            observer.next(data);
            observer.complete();
          },
          cancelFn: (data): any => {
            observer.error(data);
            observer.complete();
          }
        });
        this.confirm(confirmParameter);
      } catch (e) {
        observer.error(e);
        observer.complete();
      }
    });
  }

  public batch(parameters: {
    batchResult: any,
    errorInfos: any,
    okFn: any,
    cancelFn: any
  }): void {
    const {batchResult, errorInfos, okFn, cancelFn} = parameters;
    // const repsonse = {
    //   'data': {
    //     'batchResult': {
    //       'failCount': 1,
    //       'successCount': 0,
    //       'time': '2019/01/29 09:57:53',
    //       'title': '审核提示信息'
    //     },
    //     'errorInfos': [{
    //       'message': '订单单号：120102S991812110001',
    //       'isRemind': false,
    //       'itemInfos': [{
    //         'errorCode': 'a001',
    //         'content': '商品型号不存在于商品资料中！',
    //         'programName': '商品资料设置',
    //         'programNo': 'program01',
    //         'title': '项次1',
    //         'isRemind': false,
    //         'moreInfo': 'string'
    //       },
    //         {
    //           'errorCode': 'a002',
    //           'content': '商品价格不可小于最低价，请查询！',
    //           'programName': '商品价格查询',
    //           'programNo': 'program02',
    //           'title': '项次1',
    //           'isRemind': false,
    //           'moreInfo': 'string'
    //         }
    //       ]
    //     },
    //       {
    //         'message': '订单单号： 120102 S991812110002',
    //         'isRemind': true,
    //         'itemInfos': [{
    //           'errorCode': 'a002',
    //           'content': '此订单与订单:120102S99-1810030007，手机号/顾客姓名/地址 一致，是否确定审核?',
    //           'programName': '',
    //           'programNo': '',
    //           'title': '重复报单检查',
    //           'isRemind': true,
    //           'moreInfo': 'string'
    //         }]
    //       }
    //     ]
    //   },
    //   'success': false
    // };
    // const errorInfos = repsonse.data.errorInfos;
    //无错误和警告直接提示成功
    if (parameters.batchResult.failCount == 0 && parameters.errorInfos.length == 0) {
      this.dwMessage.success(parameters.batchResult.title+"：执行成功"+parameters.batchResult.successCount+"笔");
      if (typeof parameters.okFn === 'function') {
        okFn([]);
      }
    } else {
      const modal = this.dwModalService.create({
        dwTitle: null,
        dwStyle: {top: '20px'},
        //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
        dwMaskClosable: false, // 點擊遮罩是否允許關閉
        dwContent: FxBatchModalComponent,
        dwClosable: false,
        dwClassName: 'fx_modal_hasClose',
        dwWrapClassName: 'fx_sm_modal',
        dwWidth: window.screen.width * 0.4,
        // dwGetContainer: () => { 
        //   var spanDom = document.createElement("span");
        //   spanDom.innerText = "123";
        //   return spanDom;
        //  },
        dwOnOk: (data: any = {}): void => {
          if (typeof okFn === 'function') {
            okFn(data.data || []);
          }
        },
        dwOnCancel: cancelFn,
        dwFooter: null,
        dwComponentParams: {
          title: batchResult.title,
          successCount: batchResult.successCount,
          failCount: batchResult.failCount,
          time: batchResult.time,
          errorInfos: errorInfos
        }
      });
    }
  }

  public batch$(parameters: {
    batchResult: any,
    errorInfos: any
  }): Observable<any> {
    return Observable.create((observer) => {
      try {
        const batchParameter = Object.assign(parameters, {
          dwGetContainer: () => {
            var spanDom = document.createElement("span");
            spanDom.innerText = "123";
            return spanDom;
          },
          okFn: (data): any => {
            observer.next(data);
            observer.complete();
          },
          cancelFn: (data): any => {
            observer.error(data);
            observer.complete();
          }
        });
        this.batch(batchParameter);
      } catch (e) {
        observer.error(e);
        observer.complete();
      }
    });
  }

  /**
   * 查询开窗
   * @param parameters
   * @private
   */
  public _search(parameters: {
    title?: string,
    selected?: any,
    dataSet: any,
    columnDefs: any,
    mutilSelected?: boolean,
    isBackendPaging?: boolean,
    postParam?: any,
    pageCount?: number, 
    rowCount?: number,
    searchFn?: any,
    searchlableList?: any,
    okFn?: any,
    cancelFn?: any,
    isRequired?: boolean,
    widthMu?: any,
  }): void {
    const {title, selected, dataSet, columnDefs, mutilSelected, isBackendPaging, postParam, pageCount, rowCount, searchFn, searchlableList, okFn, cancelFn, isRequired, widthMu} = parameters;    
    var dwClassName = 'fx_md_modal';
    if (widthMu) {
      dwClassName = widthMu <= 0.6 ? 'fx_md_modal' : 'fx_lg_modal';
    }
    const modal = this.dwModalService.create({
      dwTitle: null,
      dwStyle: {top: '20px'},
      //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
      dwMaskClosable: false, // 點擊遮罩是否允許關閉
      dwContent: FxSearchModalComponent,
      dwWidth: window.screen.width * (widthMu || 0.5),
      dwFooter: null,
      dwClosable: false,
      dwWrapClassName: dwClassName,
      dwOnOk: (data: any = {}): void => {
        if (typeof okFn === 'function') {
          okFn(data.selectedItem || []);
        }
      },
      dwOnCancel: cancelFn,
      dwComponentParams: {
        title: title,
        dataSet: dataSet,
        columnDefs: columnDefs,
        isBackendPaging: isBackendPaging,
        selected: selected,
        mutilSelected: mutilSelected,          
        postParam: postParam,
        pageCount: pageCount,
        rowCount: rowCount,
        searchFn: searchFn,
        searchlableList: searchlableList,
        isRequired: isRequired,
        widthMu: widthMu
      }
    });
    
  }

  /**
   * 查询开窗
   * @param parameters
   */
  public search(parameters: {
    title?: string,
    selected?: any,
    mutilSelected?: boolean,
    columnDefs: any,
    serviceUrl: string,
    serviceParam: any,
    okFn?: any,
    cancelFn?: any
  }): void {
    const {title, columnDefs, serviceUrl, serviceParam, okFn, cancelFn} = parameters;
    const newParam = Object.assign({dataSet: []}, parameters);
    this.http.post(serviceUrl, serviceParam).subscribe((response: any) => {
      newParam.dataSet = response.data || [];     
      newParam.serviceUrl = serviceUrl;    
      newParam.serviceParam = serviceParam;
      this._search(newParam);
    });
  }

  /**
   * 查询开窗
   * @param parameters
   */
  public searchCharacteristicFx(parameters: {
    // modalID: string,          // 开窗编号
    postParam: any,    // 开窗查询参数
    // searchType: string,       // 查询类型 q.查询 a.维护 c.校验
    mutilSelected?: boolean,  // 是否多选，默认否
    selected?: any,    // 已选择，TODO
    dataInfo?: any
    // isChkAuth?: boolean       // 是否检查权限，默认 flase
  }): Observable<any> {
    const { postParam, mutilSelected, selected, dataInfo } = parameters;
    console.log(parameters)
    return Observable.create((observer) => {
      const modalParam = {
        title: '开窗查询',
        selected: selected,                 
        mutilSelected:  mutilSelected ? true : false,
        postParam: postParam,
        dataInfo: dataInfo,
        okFn: null,
        cancelFn: null
      };

      modalParam.okFn = (data): any => {
        observer.next(data);
        observer.complete();
      };
      modalParam.cancelFn = (data): any => {
        observer.error(data);
      };

      const modal = this.dwModalService.create({
        dwTitle: null,
        dwStyle: {top: '20px'},
        //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
        dwMaskClosable: false, // 點擊遮罩是否允許關閉
        dwContent: FxCharacteristicModalComponent,
        dwWidth: window.screen.width * 0.5,
        dwFooter: null,
        dwClosable: false,
        dwWrapClassName: 'fx_md_modal',
        dwOnOk: (data: any = {}): void => {
          if (typeof modalParam.okFn === 'function') {
            modalParam.okFn(data.selectedItem || []);
          }
        },
        dwOnCancel: modalParam.cancelFn,
        dwComponentParams: {
          title: modalParam.title,
          // dataSet: modalParam.dataSet,
          // columnDefs: modalParam.columnDefs,
          postParam: modalParam.postParam,
          selected: modalParam.selected,
          mutilSelected: mutilSelected,
          dataInfo: dataInfo,
        }
      });
    });
  }

  /**
   * 选配开窗
   * @param parameters
   */
   public searchOptionsFx(parameters: {
    // modalID: string,          // 开窗编号
    postParam: any,    // 开窗查询参数
    // searchType: string,       // 查询类型 q.查询 a.维护 c.校验
    mutilSelected?: boolean,  // 是否多选，默认否
    selected?: any,    // 已选择，TODO
    dataInfo?: any,
    isCreateAllBtnShow?: any,
    isDisabledCheckChrExsit?: any,
    programId?: any
    // isChkAuth?: boolean       // 是否检查权限，默认 flase
  }): Observable<any> {
    const { postParam, mutilSelected, selected, dataInfo, isCreateAllBtnShow, isDisabledCheckChrExsit, programId } = parameters;
    console.log(parameters)
    return Observable.create((observer) => {
      const modalParam = {
        title: '开窗查询',
        selected: selected,                 
        mutilSelected:  mutilSelected ? true : false,
        isCreateAllBtnShow:  isCreateAllBtnShow ? true : false,
        isDisabledCheckChrExsit:  isDisabledCheckChrExsit ? true : false,
        postParam: postParam,
        dataInfo: dataInfo,
        programId: programId,
        okFn: null,
        cancelFn: null
      };

      modalParam.okFn = (data): any => {
        observer.next(data);
        observer.complete();
      };
      modalParam.cancelFn = (data): any => {
        observer.error(data);
      };

      const modal = this.dwModalService.create({
        dwTitle: null,
        dwStyle: {top: '20px'},
        //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
        dwMaskClosable: false, // 點擊遮罩是否允許關閉
        dwContent: FxOptionsModalComponent,
        dwWidth: window.screen.width * 0.5,
        dwFooter: null,
        dwClosable: false,
        dwWrapClassName: 'fx_md_modal searchOptionsModal',
        dwOnOk: (data: any = {}): void => {
          if (typeof modalParam.okFn === 'function') {
            modalParam.okFn(data.selectedItem || []);
          }
        },
        dwOnCancel: modalParam.cancelFn,
        dwComponentParams: {
          title: modalParam.title,
          // dataSet: modalParam.dataSet,
          // columnDefs: modalParam.columnDefs,
          postParam: modalParam.postParam,
          selected: modalParam.selected,
          mutilSelected: mutilSelected,
          dataInfo: dataInfo,
          isCreateAllBtnShow: isCreateAllBtnShow,
          isDisabledCheckChrExsit: isDisabledCheckChrExsit,
          programId: programId,
        }
      });
    });
  }

   /**
   * 查询开窗
   * @param parameters
   */
    public searchFx(parameters: {
      modalID: string,          // 开窗编号
      postParam: Array<any>,    // 开窗查询参数
      searchType: string,       // 查询类型 q.查询 a.维护 c.校验
      mutilSelected?: boolean,  // 是否多选，默认否
      selected?: Array<any>,    // 已选择，TODO
      isChkAuth?: boolean ,      // 是否检查权限，默认 flase
      isHideErrorShow?: boolean,       // 是否提示，默认 flase
      no_translation?: boolean,       // 多语言替换，默认 flase
      isRequired?: boolean,       // 判断是否必须选中资料
      // startPage?: number       // 开始页码，默认 1
      // pageSize?: number        // 每页多少笔，默认 10
      widthMu?: any
    }, programId?: any): Observable<any> {
      // if (this.flag1) {
        const {modalID, postParam, mutilSelected, selected, searchType, isChkAuth, isHideErrorShow, no_translation, isRequired, widthMu} = parameters;
        const startPage = 1;
        const pageSize = 10;
        // const idx = postParam.findIndex(ele => {
        //   return ele.hasOwnProperty('zdbm');
        // });
        // if (idx !== -1) {
  
        // } else {
        //   this.flag1 = false;
        // }
        return Observable.create((observer) => {
          const modalParam: FxSearchModalparam = {
            dataSet: [],
            columnDefs: [],
            selected: selected,
            isCustomWindow: false,
            isRequired: isRequired
          };
  
          modalParam.okFn = (data): any => {
            // this.flag1 = true;
            observer.next(data);
            observer.complete();
          };
          modalParam.cancelFn = (data): any => {
            // this.flag1 = true;
            observer.error(data);
          };    
          this.getSearchModalConfig(modalID, postParam, searchType, isChkAuth, no_translation, startPage, pageSize, '', 'Y', {}, programId).subscribe((config) => {
            // 根据是否是返回的分页区分数据返回的列表
            var dataArr = []; // 存放数据集
            var isBackendPaging = false; // 是否是后端分页-默认false, false-前端分页，true-后端分页
            if (config.show_type.aao003 == 'Y') {
              isBackendPaging = true;
            }
            if (config.data) {
              if (config.data.length) {
                dataArr = config.data;
              } else {
                if (config.data.datas && config.data.datas.length) {
                  dataArr = config.data.datas;
                } else {
                  dataArr = [];
                }
              }
            } else {
              dataArr = [];
            }
            if (isBackendPaging) {
              modalParam.pageCount = config.data.pageCount ? config.data.pageCount : 0;      // 记录当前资料总笔数
              modalParam.rowCount = config.data.rowCount ? config.data.rowCount : 0;        // 记录当前多少页
              // 记录开窗是后端分页
              if (dataArr && dataArr.length) {
                dataArr[0].isBackendPaging = true;
              }
            }
            modalParam.title = config.show_type.aao001_n1;
            modalParam.mutilSelected = mutilSelected === true;
            modalParam.columnDefs = this.getSearchModalColumnConfig(config.show_type.aap_list);
            modalParam.dataSet = dataArr;
            
            if (searchType === 'c') {
              (dataArr.length > 0)
                ? observer.next(dataArr)
                : observer.error();
              observer.complete();
            } else {
              if(searchType === 'all'){
                (dataArr.length > 0)
                ? observer.next(dataArr)
                : observer.error();
              observer.complete();
              } else {
                // 设定开窗分页添加请求参数
                modalParam.postParam = {
                  modalID: modalID,
                  postParam: postParam,
                  searchType: searchType,
                  isChkAuth: isChkAuth,
                  no_translation: no_translation,
                  startPage: startPage,
                  pageSize: pageSize
                }
                modalParam.isBackendPaging = isBackendPaging;
                modalParam.searchFn = this.getSearchModalConfig;
                modalParam.searchlableList = config.show_type.aap_list;
                modalParam.widthMu = widthMu || 0.5;
                this._search(modalParam);
              }
              
            }
          }, (error) => {
            // this.flag1 = true;
            if (!isHideErrorShow) {
              this.confirm(error);
            }
            observer.error(); // by yaohuan 改动
          },() => {
            // this.flag1 = true;
          });
        });
      // }
    }
  
  /**
   * 查询开窗
   * @param parameters
   * @private
   */
  public _search_self(parameters: {
    title?: string,
    selected?: any,
    dataSet: any,
    columnDefs: any,
    mutilSelected?: boolean,
    isBackendPaging?: boolean,
    postParam?: any,
    pageCount?: number, 
    rowCount?: number,
    searchFn?: any,
    searchlableList?: any,
    okFn?: any,
    cancelFn?: any,
    isRequired?: boolean,
    widthMu?: any
    programId?: any
    isExpand?: any
    selfStyle?:any
  }): void {
    const {title, selected, dataSet, columnDefs, mutilSelected, isBackendPaging, postParam, pageCount, rowCount, searchFn, searchlableList, okFn, cancelFn, isRequired, widthMu, programId, isExpand,selfStyle} = parameters;    
    var dwClassName = 'fx_md_modal';
    if (widthMu) {
      dwClassName = widthMu <= 0.6 ? 'fx_md_modal' : 'fx_lg_modal';
    }

    const modal = this.dwModalService.create({
      dwTitle: null,
      dwStyle: {top: '20px'},
      //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
      dwMaskClosable: false, // 點擊遮罩是否允許關閉
      dwContent: FxSearchSelfModalComponent,
      dwWidth: window.screen.width * (widthMu || 0.5),
      dwFooter: null,
      dwClosable: false,
      dwWrapClassName: dwClassName,
      dwOnOk: (data: any = {}): void => {
        if (typeof okFn === 'function') {
          okFn(data.selectedItem || []);
        }
      },
      dwOnCancel: cancelFn,
      dwComponentParams: {
        title: title,
        dataSet: dataSet,
        columnDefs: columnDefs,
        isBackendPaging: isBackendPaging,
        selected: selected,
        mutilSelected: mutilSelected,          
        postParam: postParam,
        pageCount: pageCount,
        rowCount: rowCount,
        searchFn: searchFn,
        searchlableList: searchlableList,
        isRequired: isRequired,
        widthMu: widthMu,
        programId: programId,
        isExpand: isExpand,
        selfStyle:selfStyle
      }
    });
    
  }
    /**
   * 查询开窗
   * @param parameters
   */
    public search_self(parameters: {
      title?: string,
      selected?: any,
      mutilSelected?: boolean,
      columnDefs: any,
      serviceUrl: string,
      serviceParam: any,
      okFn?: any,
      cancelFn?: any,
      selfStyle?:any
    }): void {
      const {title, columnDefs, serviceUrl, serviceParam, okFn, cancelFn,selfStyle} = parameters;
      const newParam = Object.assign({dataSet: []}, parameters);
      this.http.post(serviceUrl, serviceParam).subscribe((response: any) => {
        newParam.dataSet = response.data || [];     
        newParam.serviceUrl = serviceUrl;    
        newParam.serviceParam = serviceParam;
        this._search_self(newParam);
      });
    }
    /**
     * 查询开窗
     * @param parameters
     */
  public searchFxSelf(parameters: {
    modalID: string,          // 开窗编号
    postParam: Array<any>,    // 开窗查询参数
    searchType: string,       // 查询类型 q.查询 a.维护 c.校验
    mutilSelected?: boolean,  // 是否多选，默认否
    selected?: Array<any>,    // 已选择，TODO
    isChkAuth?: boolean ,      // 是否检查权限，默认 flase
    isHideErrorShow?: boolean,       // 是否提示，默认 flase
    no_translation?: boolean,       // 多语言替换，默认 flase
    isRequired?: boolean,       // 判断是否必须选中资料
    // startPage?: number       // 开始页码，默认 1
    // pageSize?: number        // 每页多少笔，默认 10
    widthMu?: any,
    selfStyle?:any
  }, programId?: any): Observable<any> {
    // if (this.flag1) {
      const {modalID, postParam, mutilSelected, selected, searchType, isChkAuth, isHideErrorShow, no_translation, isRequired, widthMu,selfStyle} = parameters;
      const startPage = 1;
      const pageSize = 10;
      // const idx = postParam.findIndex(ele => {
      //   return ele.hasOwnProperty('zdbm');
      // });
      // if (idx !== -1) {

      // } else {
      //   this.flag1 = false;
      // }
      return Observable.create((observer) => {
        const modalParam: FxSearchModalparam = {
          dataSet: [],
          columnDefs: [],
          selected: selected,
          isCustomWindow: false,
          isRequired: isRequired,
          selfStyle:[]
        };

        modalParam.okFn = (data): any => {
          // this.flag1 = true;
          observer.next(data);
          observer.complete();
        };
        modalParam.cancelFn = (data): any => {
          // this.flag1 = true;
          observer.error(data);
        };    
        this.getSearchModalConfig(modalID, postParam, searchType, isChkAuth, no_translation, startPage, pageSize, '', 'Y', {joinOperator: "AND", items: []}, programId).subscribe((config) => {
          // 根据是否是返回的分页区分数据返回的列表
          var dataArr = []; // 存放数据集
          var isBackendPaging = false; // 是否是后端分页-默认false, false-前端分页，true-后端分页
          if (config.show_type.aao003 == 'Y') {
            isBackendPaging = true;
          }
          var isExpand = config.show_type.aao009 == 'Y'; 
          if (config.data) {
            if (config.data.length) {
              dataArr = config.data;
            } else {
              if (config.data.datas && config.data.datas.length) {
                dataArr = config.data.datas;
              } else {
                dataArr = [];
              }
            }
          } else {
            dataArr = [];
          }
          if (isBackendPaging) {
            modalParam.pageCount = config.data.pageCount ? config.data.pageCount : 0;      // 记录当前资料总笔数
            modalParam.rowCount = config.data.rowCount ? config.data.rowCount : 0;        // 记录当前多少页
            // 记录开窗是后端分页
            if (dataArr && dataArr.length) {
              dataArr[0].isBackendPaging = true;
            }
          }
          modalParam.title = config.show_type.aao001_n1;
          modalParam.mutilSelected = mutilSelected === true;
          modalParam.columnDefs = this.getSearchModalColumnConfig(config.show_type.aap_list);
          modalParam.dataSet = dataArr;
          
          if (searchType === 'c') {
            (dataArr.length > 0)
              ? observer.next(dataArr)
              : observer.error();
            observer.complete();
          } else {
            if(searchType === 'all'){
              (dataArr.length > 0)
              ? observer.next(dataArr)
              : observer.error();
            observer.complete();
            } else {
              // 设定开窗分页添加请求参数
              modalParam.postParam = {
                modalID: modalID,
                postParam: postParam,
                searchType: searchType,
                isChkAuth: isChkAuth,
                no_translation: no_translation,
                startPage: startPage,
                pageSize: pageSize,
                selfStyle:selfStyle
              }
              modalParam.isBackendPaging = isBackendPaging;
              modalParam.searchFn = this.getSearchModalConfig;
              modalParam.searchlableList = config.show_type.aap_list;
              modalParam.widthMu = widthMu || 0.5;
              modalParam.programId = programId;
              modalParam.isExpand = isExpand;
              modalParam.selfStyle = selfStyle;
              this._search_self(modalParam);
            }
            
          }
        }, (error) => {
          // this.flag1 = true;
          if (!isHideErrorShow) {
            this.confirm(error);
          }
          observer.error(); // by yaohuan 改动
        },() => {
          // this.flag1 = true;
        });
      });
    // }
  }


  private getSearchModalConfig(qry_no: string, paramList: Array<any>, searchType: string, isChkAuth?: boolean, no_translation?: boolean, startPage?: number, pageSize?: number, glikesel?: string, isFirst?: string, condition?: any, programId?: any): Observable<any> {
    var noTranslation; // 多语言替换
     if (qry_no == 'qry_caigoods' || qry_no == 'qry_daecust') { // 如果是qry_caigoods需要设置no_translation为Y否则会报错，为了不报错暂时不在作业里面单独处理
       noTranslation = true;
     } else {
       noTranslation = no_translation;
     }
     isChkAuth = isChkAuth === true || isChkAuth === undefined ? true : false;
    const postParam = {
      param: {
        qry_no: qry_no,
        param_list: paramList,
        qry_type: searchType === 'all' ? 'a' : searchType,
        isChkAuth: isChkAuth ? 'Y' : 'N',
        no_translation: noTranslation ? 'Y' : 'N',
        start_page: startPage ? startPage : 1,
        page_size: pageSize ? pageSize : 10,
        glikesel: glikesel ? glikesel : '',
        isFirst: isFirst,
        condition: condition ? condition : {}
      }
    };
    return Observable.create((observer) => {
      var headerV = programId ? { headers: {'program-code': programId} } : {};
      this.http.post('A/IACommonService/qryPubService', postParam, headerV).subscribe((response: any) => {
        if (response.success) {
          
          observer.next({
            show_type: response.show_type as FxSearchModelConfig,
            data: response.data
          });
        } else {
          observer.error(response);
        }
        observer.complete();
      }, error => {
        observer.error(error);
        this.confirm
      });
    });
  }

  private getSearchModalColumnConfig(colums: Array<FxSearchModelColumn>): any {
    if (colums.length < 1) {
      return [];
    }
    console.log(colums)
    return colums.sort((a, b): number => {
      return Number(a.aapseq) - Number(b.aapseq);
    }).map(colum => {
      if(colum.aap003=='3'){ //aap003: string// 显示类型 // { label: '下拉', value: '3' },
        console.log(colum.aap004);
        // colum.optionsaap004$ = this.fxUserService.getSysEnumById$(colum.aap004);
        // console.log(colum.optionsaap004$);
        return {
          headerName: colum.aap002_n1,
          field: colum.aap002,
          cellRenderer: 'fxGridSelectRenderer',
          cellRendererParams: {
            values: this.fxUserService.getSysEnumById$(colum.aap004),
           },
        }; 
      }
      if(colum.aap003=='4'){     // { label: '勾选', value: '4' },
        return {
          headerName: colum.aap002_n1,
          field: colum.aap002,
          cellRenderer: 'fxCheckboxCellRenderer',
          cellRendererParams: {
            dwTrueVal: 'Y',
            dwFalseVal: 'N',
            dwDisabled: true
          }
        };
      }else{
        return {
          headerName: colum.aap002_n1,
          field: colum.aap002,
        };
      }
     
    });
  }
  public pickIcon(parameters?: {
    title?: string,
    iconLib?: string
  }): Observable<string> {
    let {title, iconLib} = parameters || {title: '选择图标', iconLib: 'anticon'};
    title = title ? title : '选择图标';
    iconLib = iconLib ? iconLib : 'anticon';

    return Observable.create((observer) => {
      this.dwModalService.create({
        dwTitle: null,
        dwWidth: window.screen.width * 0.5,
        dwStyle: {top: '20px'},
        //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
        dwMaskClosable: false, // 點擊遮罩是否允許關閉
        dwWrapClassName: 'fx_md_modal',
        dwContent: FxIconPickerModalComponent,
        dwOnOk: (modal: any = {}): void => {

          observer.next(modal.selectedIcon.class);
          observer.complete();
        },
        dwOnCancel: (data: any = {}): void => {
          observer.error();
        },
        dwFooter: null,
        dwComponentParams: {
          iconLib: iconLib
        }
      });
    });

  }
}
